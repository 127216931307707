import axios from 'axios';
import * as Sentry from '@sentry/vue';
import {CookieService} from '@/services/cookie';
import { sessionServiceDomain } from '@/domain/session';
import { ErrorChecker } from './AxiosErrorChecker';
import { axiosClientInterceptorHandlers } from './axiosClientInterceptorHandlers';

const authToken = CookieService.getCookie('access_token');

const axiosOptions = {
    withCredentials: true,
    baseURL: `${process.env.VUE_APP_BACKEND_GATEWAY_URL}/v1`,
};

if (authToken) {
    axiosOptions.headers = {
        Authorization: `Bearer ${authToken}`,
        'Accept-Language': 'en',
    };
}

const axiosClient = axios.create(axiosOptions);

const isTokenExpired = (token) => {
    if (!token) {
        return true;
    }
    const decode = JSON.parse(atob(token.split('.')[1]));

    if (decode.exp * 1000 < new Date().getTime()) {
        return true;
    }

    return false;
};

axiosClient.interceptors.request.use(
    async (config) => {
        const accessToken = CookieService.getCookie('access_token');
        const refreshToken = CookieService.getCookie('refresh_token');

        if (config.url.includes('oauth/token') || config.url.includes('send-verification-code') || config.url.includes('sign-in-with-email')) {
            return config;
        }

        if ((!accessToken || isTokenExpired(accessToken)) && refreshToken) {
            try {
                const response = await getAccessTokenFromRefreshToken(refreshToken);
                const { data } = response;
                config.headers['Authorization'] = `Bearer ${data.access_token}`;
                sessionServiceDomain.setLoginCookies(data);
                return config;
            } catch (error) {
                sessionServiceDomain.userLogout();
                window.location.href = '/#/?action=login';
                return;
            }
        }

        if ((!accessToken || isTokenExpired(accessToken)) && isTokenExpired(refreshToken)) {
            window.location.href = '/#/?action=login';
            return;
        }

        if (!config.headers['Authorization']) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
);

// axiosClient.interceptors.response.use(
//     (response) => response,
//     (error) => handleRequestError(error),
// );

export const handleRequestError = async (error) => {
    const { status, description } = error.response;
    const originalRequest = error.config;
    const refreshToken = CookieService.getCookie('refresh_token');
    Sentry.captureException(error);

    const axiosClientErrorChecker = ErrorChecker(status, description, originalRequest);

    if (axiosClientInterceptorHandlers.isViewLevelHandled(originalRequest, axiosClientErrorChecker)) {
        return Promise.reject(error);
    }

    if (axiosClientErrorChecker.hasInvalidAuthentication() && axiosClientErrorChecker.hasInvalidDescription()) {
        window.location.href = '/#/?action=login';
        return;
    }

    if (axiosClientErrorChecker.hasInvalidAuthentication() && !originalRequest._retry && refreshToken) {
        originalRequest._retry = true;
        try {
            const { data } = await getAccessTokenFromRefreshToken(refreshToken);
            return axiosClientInterceptorHandlers.setUserInfo(axiosClient, data, error, originalRequest);
        } catch (error) {
            axiosClientInterceptorHandlers.nonValidRefreshTokenRequest(error);
            return;
        }
    }

    window.location.href = `/#/account-activated-error${axiosClientInterceptorHandlers.getQueryAction()}`;
};

const getAccessTokenFromRefreshToken = (refreshToken) => {
    const data = {
        withCredentials: true,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: process.env.VUE_APP_WEB_CLIENT_ID,
        client_secret: process.env.VUE_APP_WEB_CLIENT_SECRET,
    };

    return axios.post(
        `${process.env.VUE_APP_BACKEND_GATEWAY_URL}/v1/oauth/token`, data, {
            headers: {
                'request-id': 1,
            },
        },
    );
};
const updateAuthToken = (token) => {
    axiosClient.defaults.headers['Authorization'] = `Bearer ${token}`;
};


export {
    axiosClient,
    updateAuthToken,
};
